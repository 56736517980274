export default {
    dark: {
        '--primary': '#05259D',
        '--success': '#FDA702',
        '--text-color': '#ffffff',
        '--text-color-light': '#ffffff',
        '--bg-color': '#151618',
        '--border-color': '#2B2C30',
        '--head-bg-color': '#161596',
        '--tab-bg-color': '#151618',
        '--group-bg-color': '#000',
        '--red-bg-color':'#420B26',
        '--menu-bg-color': '#0A1C5D',
        '--integral-group': '#131A3E',
        '--integral-text': '#D2D4D9',
        '--menu-text': '#ffffff',

        '--menu-active': '#FFFFFF',
        '--tab-text': '#ffffff',
        '--out-item': '#000000',
        '--out-title': '#ffffff',
        '--scmenu-bg-color': '#0A1C5E',
        '--out-line': '#4E4E4E'
    },
    daily: {
        '--primary': '#05259D',
        '--success': '#FDA702',
        '--text-color': '#131A3E',
        '--text-color-light': '#5A6375',
        '--bg-color': '#ffffff',
        '--border-color': '#EAEDF6',
        '--head-bg-color': '#161596',
        '--tab-bg-color': '#ffffff',
        '--group-bg-color': '#EAEDF6',
        '--red-bg-color':'#FFE4F1',
        '--menu-bg-color': '#FFE4F1',
        '--integral-group': '#131A3E',
        '--integral-text': '#FFFFFF',
        '--menu-text': '#131A3E',
        '--menu-active': '#05259D',
        '--tab-text': '#131A3E',
        '--out-item': '#ffffff',
        '--out-title': '#05259D',
        '--scmenu-bg-color': '#CAD4FF',
        '--out-line': '#D9D9D9'

    }
}